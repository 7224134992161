type ArrowProps = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  color: string;
  direction: 1 | -1;
  scale: number;
};

const arrowLength = 100;
const arrowSize = 30;
const toDegrees = (radians: number) => {
  return (radians * 180) / Math.PI;
};

export const getPerpendicularCoords = (
  x1: number,
  x2: number,
  y1: number,
  y2: number,
  direction: number,
  length: number,
  swapCoef: number,
  x_boundary?: number,
  y_boundary?: number
) => {
  const boundary_buffer = 20;
  let px =
    x1 +
    (direction * length * swapCoef) /
      Math.sqrt(1 + (x2 - x1) ** 2 / (y2 - y1) ** 2);
  let py = y1 - ((x2 - x1) * (px - x1)) / (y2 - y1) || y1 + direction * length;
  // px/py - координата x/y перпендикуляра
  if (x_boundary) {
    if (px > x_boundary) {
      px = x_boundary - boundary_buffer;
    }
    if (px <= 0) {
      px = boundary_buffer;
    }
  }
  if (y_boundary) {
    if (py > y_boundary) {
      py = y_boundary - boundary_buffer;
    }
    if (py <= 0) {
      py = boundary_buffer;
    }
  }
  return { px, py };
};

export const getDistance = (
  x1: number,
  x2: number,
  y1: number,
  y2: number,
  direction: number,
  swapCoef: number,
  px: number,
  py: number
) => {
  // рассчет расстояние через координату X
  const distanceX = Math.abs(
    ((px - x1) * Math.sqrt(1 + (x2 - x1) ** 2 / (y2 - y1) ** 2)) /
      (direction * swapCoef)
  );

  // рассчет расстояние через координату Y
  const distanceY = Math.abs((py - y1) / direction);

  // т.к. при изменении расстояния у почти горизонтальной сигнальной линии
  // расчет через координату X ведет себя не стабильно
  if (Math.abs(y2 - y1) < 50) return Math.trunc(distanceY);
  return Math.trunc(distanceX);
};

const Arrow = ({ x1, x2, y1, y2, color, direction, scale }: ArrowProps) => {
  const centerX = x1 - (x1 - x2) / 2;
  const centerY = y1 - (y1 - y2) / 2;
  // поменять направление линий, если меняется знак разности координат Y
  // без коэфициента при y2 > y1 линии будут меняться местами
  const swapCoef = Math.abs(y1 - y2) / (y1 - y2) || 1;
  const { px: x3, py: y3 } = getPerpendicularCoords(
    centerX,
    x2,
    centerY,
    y2,
    direction,
    arrowLength,
    swapCoef
  );

  const { px: arrowX, py: arrowY } = getPerpendicularCoords(
    centerX,
    x2,
    centerY,
    y2,
    direction,
    arrowLength - arrowSize / 3.5,
    swapCoef
  );

  const angle = toDegrees(Math.atan2(x1 - x2, y1 - y2)); // rotation angle of signal line
  return (
    <>
      <line
        x1={centerX}
        y1={centerY}
        x2={x3 || 0}
        y2={y3}
        stroke={color}
        strokeWidth={4 * scale}
      />
      <g
        fill={color}
        style={{
          transformOrigin: "center",
          transform: `rotate(${-angle - 90 * (direction - 1)}deg)`, // развернуть стрелку на 180 градусов относительно противоположной
          transformBox: "fill-box",
        }}
      >
        <svg
          x={arrowX - (arrowSize / 2) * scale || 0} // поместить центр svg в координату x6
          y={arrowY - (arrowSize / 2) * scale} // поместить центр svg в координату y6
          width={arrowSize * scale}
          height={arrowSize * scale}
          viewBox="0 0 306 306"
        >
          <polygon points="58.65,267.75 175.95,153 58.65,35.7 94.35,0 247.35,153 94.35,306" />
        </svg>
      </g>
    </>
  );
};

export default Arrow;
