import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "../../store/reduxHooks";
import {
  setCameraList,
  setSelectedCamera,
  setShowCameraList,
} from "../../store/reducers/cameraReducer";
import { deleteCamera as deleteCamRequest } from "../functions";
import useNotify from "./useNotify";
import { useSearchParams } from "react-router-dom";

const { API_URL } = window;

const useCamera = () => {
  const dispatch = useDispatch();
  const { notify } = useNotify();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    cameraList,
    selectedCamId,
    showCameraList,
    readonly,
    editCameraId,
    videoUrl,
  } = useSelector((state) => state.camera);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showEditCam, setShowEditCam] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleSelect = (id: string) => {
    setSearchParams({ camId: id });
    dispatch(setSelectedCamera(id));
  };

  const deleteCamera = async (id: string) => {
    try {
      setShowLoader(true);
      await deleteCamRequest(id);
      const newList = cameraList.filter((c) => c.id !== id);
      // обновить список камер
      dispatch(setCameraList(newList));
    } catch (e) {
      notify("Camera has not been found!", "error");
    } finally {
      if (selectedCamId === id) {
        // не выбирать текущую камеру, если она была удалена
        handleSelect("");
      }
      setShowDeleteConfirm(false);
      setShowLoader(false);
    }
  };

  const getCamera = async (camId: string) => {
    try {
      const res = await fetch(`${API_URL}/cameras/${camId}`);
      return res.json();
    } catch (e) {
      notify("Camera has not been found!", "error");
    }
  };

  useEffect(() => {
    setShowEditCam(!!editCameraId);
  }, [editCameraId]);

  useEffect(() => {
    // выбрать камеру, если её id указан в url
    const camId = searchParams.get("camId");
    if (camId) {
      dispatch(setSelectedCamera(camId));
    }
  }, []);

  return {
    dispatch,
    cameraList,
    getCamera,
    setCameraList,
    handleSelect,
    selectedCamId,
    deleteCamera,
    setShowCameraList,
    showCameraList,
    setShowDeleteConfirm,
    showDeleteConfirm,
    showLoader,
    readonly,
    showEditCam,
    setShowEditCam,
    videoUrl,
  };
};

export default useCamera;
