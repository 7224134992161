import deleteIcon from "../../../assets/delete.svg";
import { useState } from "react";

type Props = {
  p: [number, number];
  onClick: () => void;
};

const SMALL_SIZE = 80;
const BIG_SIZE = 90;

const DeleteRoi = ({ onClick, p }: Props) => {
  const [size, setSize] = useState(SMALL_SIZE);
  return (
    <image
      onMouseEnter={() => {
        setSize(BIG_SIZE);
      }}
      onMouseLeave={() => {
        setSize(SMALL_SIZE);
      }}
      onClick={onClick}
      x={p[0] - size / 2}
      y={p[1] - size / 2}
      width={size}
      href={deleteIcon}
      style={{ cursor: "pointer" }}
    />
  );
};

export default DeleteRoi;
