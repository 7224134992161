import { createGlobalStyle } from "styled-components";

const GlobalScrollStyles = createGlobalStyle`
/* total width */
*::-webkit-scrollbar {
  width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  margin: 5px 0;
  background-color: rgba(255, 255, 255, 0);
}
body::-webkit-scrollbar-track:hover {
  background-color: rgba(255, 255, 255, 0);
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}
`;

export default GlobalScrollStyles;
