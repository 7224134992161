import { createSlice } from "@reduxjs/toolkit";

type InitStateType = {
  coords: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  roiCoords: [number, number, number, number] | null;
  name: string;
  directions: {
    left: { name: string };
    right: { name: string };
  };
  event_generation_parameters: { distance_threshold_px: number };
  markOutDirection: "left" | "right" | null;
};

const initialState: InitStateType = {
  coords: { x1: 0, y1: 0, x2: 0, y2: 0 },
  roiCoords: null,
  name: "Default Name",
  directions: {
    left: { name: "Лево" },
    right: { name: "Право" },
  },
  event_generation_parameters: { distance_threshold_px: 100 },
  markOutDirection: null,
};

export const editSlice = createSlice({
  name: "edit",
  initialState,
  reducers: {
    setRoiCoords: (state, action) => {
      state.roiCoords = action.payload;
    },
  },
});

export const { setRoiCoords } = editSlice.actions;

export default editSlice.reducer;
