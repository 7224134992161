import { FC, HTMLProps, useCallback, useEffect, useState } from "react";
import styles from "./VideoContainer.module.scss";
import classNames from "classnames";
import { getCameraById, getImageSize } from "../../utils/functions";
import { createEventThresholdLines, toDataURL } from "./functions";
import { bbox } from "../../store/reducers/cameraReducer";
import useCamera from "../../utils/hooks/useCamera";
import RoiMask from "../EditCamera/EditRoi/RoiMask";
import CamNotSpecified from "../CamNotSpecified";
import NoImage from "./no_image.png";
import theme from "../../theme";
import Arrow from "../EditCamera/EditSignalLine/Arrow";

const { API_URL } = window;

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const VideoContainer: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const { selectedCamId, cameraList, videoUrl, dispatch } = useCamera();
  const selectedCamera = getCameraById(selectedCamId, cameraList); // объект камеры
  // используется словарь, чтобы при смене камеры кадры со старой и текущей камер не перемешивались
  const [image, setImage] = useState({ [`${selectedCamId}`]: "" });
  const [error, setError] = useState(false);
  // габариты картинки сохраняются для передачи их svg контейнеру
  const [imageParams, setImageParams] = useState({ width: 0, height: 0 });
  const [lineCoordinates, setLineCoordinates] = useState<bbox>([0, 0, 0, 0]);
  const [roiCoords, setRoiCoords] = useState<bbox>([0, 0, 0, 0]);
  const [Line1Coords, setLine1Coords] = useState<bbox>([0, 0, 0, 0]);
  const [Line2Coords, setLine2Coords] = useState<bbox>([0, 0, 0, 0]);

  const imageRef = useCallback(
    (node: SVGImageElement) => {
      // пересчет размеров картинки происходит при смене камеры
      // т.к. камеры в большинстве имеют отличные форматы
      if (node !== null) {
        getImageSize(
          setImageParams,
          `${API_URL}/cameras/${selectedCamId}/frame`
        );
      }
    },
    [selectedCamId]
  );

  useEffect(() => {
    const signalLines =
      selectedCamera && selectedCamera.signal_lines
        ? Object.values(selectedCamera.signal_lines)
        : undefined;

    if (signalLines) {
      // получить первую сигнальную линию
      const signalLine = signalLines[0];
      const [x1, y1, x2, y2] = signalLine.coordinates;
      setLineCoordinates([x1, y1, x2, y2]);
      const { distance_threshold_px: distance } =
        signalLine.event_generation_parameters;
      createEventThresholdLines(
        signalLine.coordinates,
        distance,
        setLine1Coords,
        setLine2Coords
      );
      const { roi } = selectedCamera.head_detection_parameters;
      roi && setRoiCoords(roi);
    } else {
      setLineCoordinates([0, 0, 0, 0]);
    }
  }, [selectedCamera]);

  useEffect(() => {
    // если у камеры меняется источник видео
    // подождать 5сек обновление фреймов на сервере
    // обновить размеры изображения до актуальных
    const timer = setTimeout(() => {
      setError(false);
      getImageSize(setImageParams, `${API_URL}/cameras/${selectedCamId}/frame`);
    }, 5000);
    return () => clearTimeout(timer);
  }, [videoUrl]);

  useEffect(() => {
    // получать фрейм каждые 0.15сек, если нет ошибок
    if (selectedCamId && !error) {
      const interval = setInterval(() => {
        toDataURL(setImage, selectedCamId, setError);
      }, 150);
      return () => {
        clearInterval(interval);
      };
    }
  }, [selectedCamId, error]);

  useEffect(() => {
    // очистить ошибку старой камеры выборе новой
    setError(false);
  }, [selectedCamId]);

  return (
    <div className={className} {...props}>
      {selectedCamera && !error ? (
        <>
          <svg viewBox={`0 0 ${imageParams.width} ${imageParams.height}`}>
            <image ref={imageRef} href={image[selectedCamId]}></image>
            <g>
              <rect
                x="0"
                y="0"
                width={selectedCamera.name.length * 40}
                height="80"
                fill="rgba(1,36,162, 0.5)"
              ></rect>
              <text
                x="20"
                y="50"
                fontFamily="Suisse Intl"
                fontSize="50"
                fill="#fff"
              >
                {selectedCamera.name}
              </text>
            </g>
            <line
              x1={lineCoordinates[0]}
              y1={lineCoordinates[1]}
              x2={lineCoordinates[2]}
              y2={lineCoordinates[3]}
              stroke="red"
              strokeWidth={4}
            />
            <line
              x1={Line1Coords[0]}
              y1={Line1Coords[1]}
              x2={Line1Coords[2]}
              y2={Line1Coords[3]}
              stroke={theme.customPallete.eventLine}
              strokeWidth={4}
              strokeDashoffset="1"
              strokeDasharray="10,10"
            />
            <line
              x1={Line2Coords[0]}
              y1={Line2Coords[1]}
              x2={Line2Coords[2]}
              y2={Line2Coords[3]}
              stroke={theme.customPallete.eventLine}
              strokeWidth={4}
              strokeDashoffset="1"
              strokeDasharray="10,10"
            />
            <Arrow
              x1={lineCoordinates[0]}
              y1={lineCoordinates[1]}
              x2={lineCoordinates[2]}
              y2={lineCoordinates[3]}
              direction={1}
              color="#fff"
              scale={1}
            />
            <Arrow
              x1={lineCoordinates[0]}
              y1={lineCoordinates[1]}
              x2={lineCoordinates[2]}
              y2={lineCoordinates[3]}
              direction={-1}
              color="#0124A2"
              scale={1}
            />
            <RoiMask
              imgWidth={imageParams.width}
              imgHeight={imageParams.height}
              roiCoords={selectedCamera.head_detection_parameters.roi}
              id="videoContainer"
            />
          </svg>
        </>
      ) : error ? (
        <img src={NoImage} style={{ height: "100%" }} />
      ) : (
        <CamNotSpecified />
      )}
    </div>
  );
};

export default VideoContainer;
