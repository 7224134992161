import { useEffect, useRef } from "react";
import { setEvents, addEvent } from "../../store/reducers/eventsReducer";
import { useSelector, useDispatch } from "../../store/reduxHooks";

const usePrevious = (value: string) => {
  const ref = useRef<string>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const { API_URL } = window;
const ws_url = API_URL.replace("http", "ws");

const ws = new WebSocket(`${ws_url}/subscribe`);
const useEvent = () => {
  const dispatch = useDispatch();
  const { selectedCamId } = useSelector((state) => state.camera);
  const { eventsList } = useSelector((state) => state.events);
  // сохранение id предыдущей камеры, для последующей отписки
  const prevCamId = usePrevious(selectedCamId);

  const subscribeToEvent = (camId: string, prevCamId: string | undefined) => {
    // wait until websocket opens connection
    if (ws.readyState !== 1) {
      setTimeout(() => subscribeToEvent(camId, prevCamId), 1000);
      return;
    }
    const subsribeParams = {
      action: "subscribe",
      subscription_type: "events",
      cameras: [camId],
    };
    const unsubscribeParams = {
      action: "unsubscribe",
      subscription_type: "events",
      cameras: [prevCamId],
    };
    if (prevCamId) {
      // отписаться от предыдущей камеры
      ws.send(JSON.stringify(unsubscribeParams));
    }
    // подписаться на выбранную камеру
    ws.send(JSON.stringify(subsribeParams));
    ws.onmessage = (e) => {
      const json = JSON.parse(e.data);
      if (json.event) {
        // сохранить событие
        dispatch(addEvent(json.event));
      }
    };
  };

  useEffect(() => {
    if (selectedCamId) {
      subscribeToEvent(selectedCamId, prevCamId);
      // удалить старые события при смене камеры
      dispatch(setEvents([]));
    }
  }, [selectedCamId]);

  return { eventsList };
};

export default useEvent;
