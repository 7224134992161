import useEditLine from "./useEditLine";
import { useEditScreenContext } from "../EditScreen/EditScreenProvider";
import Arrow from "./Arrow";
import theme from "../../../theme";
import EditPoint from "./EditPoint";
import { useEffect, useRef, useState } from "react";
import { getCoeffs, getParallelLineCoords } from "./functions";

const checkPointInBoundaries = (
  x: number,
  y: number,
  width: number,
  height: number
) => {
  return x > 0 && x < width && y > 0 && y < height;
};

const EditSignalLine = ({
  svgRef,
}: {
  svgRef: React.RefObject<SVGSVGElement>;
}) => {
  const {
    SL_MarkOutDirection,
    SL_Coords,
    setSL_Coords,
    SL_EGP,
    setSL_EGP,
    formDistanceThreshold,
    setSl_Dt_OutOfBoundary,
  } = useEditLine();

  const { imageParams } = useEditScreenContext();
  const { x1, y1, x2, y2 } = SL_Coords;
  const leftArrowScale = SL_MarkOutDirection === "left" ? 3 : 1;
  const rightArrowScale = SL_MarkOutDirection === "right" ? 3 : 1;
  const { distance_threshold_px } = SL_EGP;
  const [threshold1, setThreshold1] = useState(
    getParallelLineCoords(x1, x2, y1, y2, 1, distance_threshold_px)
  );
  const [threshold2, setThreshold2] = useState(
    getParallelLineCoords(x1, x2, y1, y2, -1, distance_threshold_px)
  );

  useEffect(() => {
    if (!imageParams.width) return;
    const { threshold1, threshold2, inBoundaries } = updateThresholds(
      x1,
      y1,
      x2,
      y2,
      formDistanceThreshold
    );
    if (inBoundaries) {
      setThreshold1(threshold1);
      setThreshold2(threshold2);
      setSl_Dt_OutOfBoundary(false);
    } else {
      setSl_Dt_OutOfBoundary(true);
    }
  }, [formDistanceThreshold]);

  const updateThresholds = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    distance: number
  ) => {
    const threshold1 = getParallelLineCoords(x1, x2, y1, y2, 1, distance);
    const threshold2 = getParallelLineCoords(x1, x2, y1, y2, -1, distance);
    const inBoundaries =
      checkPointInBoundaries(
        threshold1.centerX,
        threshold1.centerY,
        imageParams.width,
        imageParams.height
      ) &&
      checkPointInBoundaries(
        threshold2.centerX,
        threshold2.centerY,
        imageParams.width,
        imageParams.height
      );
    return { threshold1, threshold2, inBoundaries };
  };

  const handlePointChange1 = (x: number, y: number) => {
    const { threshold1, threshold2, inBoundaries } = updateThresholds(
      x,
      y,
      x2,
      y2,
      distance_threshold_px
    );
    if (!inBoundaries) return;
    setThreshold1(threshold1);
    setThreshold2(threshold2);
    setSL_Coords((prev) => {
      return { ...prev, x1: x, y1: y };
    });
    setSl_Dt_OutOfBoundary(false);
  };
  const handlePointChange2 = (x: number, y: number) => {
    const { threshold1, threshold2, inBoundaries } = updateThresholds(
      x1,
      y1,
      x,
      y,
      distance_threshold_px
    );
    if (!inBoundaries) return;
    setThreshold1(threshold1);
    setThreshold2(threshold2);
    setSL_Coords((prev) => {
      return { ...prev, x2: x, y2: y };
    });
    setSl_Dt_OutOfBoundary(false);
  };

  const handleDistanceChange = (x: number, y: number) => {
    const { a, b, c } = getCoeffs({ x: x1, y: y1 }, { x: x2, y: y2 });
    const distance = Math.trunc(
      Math.abs(a * x + b * y + c) / Math.sqrt(a ** 2 + b ** 2)
    );
    const { threshold1, threshold2, inBoundaries } = updateThresholds(
      x1,
      y1,
      x2,
      y2,
      distance
    );
    if (!inBoundaries) return;
    setThreshold1(threshold1);
    setThreshold2(threshold2);
    setSL_EGP({ distance_threshold_px: distance });
    setSl_Dt_OutOfBoundary(false);
  };

  return (
    <>
      <Arrow
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        direction={1}
        scale={rightArrowScale}
        color="#fff"
      />
      <Arrow
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        direction={-1}
        scale={leftArrowScale}
        color="#0124A2"
      />
      <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="red" strokeWidth={4} />
      <line
        x1={threshold1.p1.px}
        y1={threshold1.p1.py}
        x2={threshold1.p2.px}
        y2={threshold1.p2.py}
        stroke={theme.customPallete.eventLine}
        strokeWidth={4}
        strokeDasharray="10,10"
      />
      <line
        x1={threshold2.p1.px}
        y1={threshold2.p1.py}
        x2={threshold2.p2.px}
        y2={threshold2.p2.py}
        stroke={theme.customPallete.eventLine}
        strokeWidth={4}
        strokeDasharray="10,10"
      />
      <EditPoint
        p={[x1, y1]}
        handlePointChange={handlePointChange1}
        svgRef={svgRef}
      />
      <EditPoint
        p={[x2, y2]}
        handlePointChange={handlePointChange2}
        svgRef={svgRef}
      />
      <EditPoint
        p={[threshold1.centerX, threshold1.centerY]}
        handlePointChange={handleDistanceChange}
        svgRef={svgRef}
        color={theme.customPallete.eventLine}
      />
      <EditPoint
        p={[threshold2.centerX, threshold2.centerY]}
        handlePointChange={handleDistanceChange}
        svgRef={svgRef}
        color={theme.customPallete.eventLine}
      />
    </>
  );
};

export default EditSignalLine;
