import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import { useEffect, useState } from "react";
import { getAllCameras, getReadOnlyRights } from "./utils/functions";
import useCamera from "./utils/hooks/useCamera";
import Notification from "./components/Notification";
import useNotify from "./utils/hooks/useNotify";
import CameraList from "./components/CameraList";
import { setReadOnly } from "./store/reducers/cameraReducer";
import BottomSection from "./components/BottomSection";
import Navigation from "./components/Navigation";
import AddCamera from "./components/AddCamera";

function App() {
  const { dispatch, setCameraList } = useCamera();
  const { notify } = useNotify();
  useEffect(() => {
    const foo = async () => {
      try {
        const res = await getAllCameras();
        const resRights = await getReadOnlyRights();
        dispatch(setReadOnly(resRights));
        dispatch(setCameraList(res));
      } catch (err) {
        notify("Cannot get cameras", "error");
      }
    };
    foo();
  }, [dispatch]);

  useEffect(() => {
    (window as any).ym(window.YANDEX_METRIC_ID, "hit", window.location.href);
  }, []);

  // TODO: убрать из проекта React router
  return (
    <div className="app-container">
      <div className="max-width">
        <Navigation />
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
      <CameraList />
      <AddCamera />
      <Notification />
      <BottomSection />
    </div>
  );
}

export default App;
