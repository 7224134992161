import { Button, TextField } from "@mui/material";
import React from "react";
import { setRoiCoords } from "../../../store/reducers/editReducer";
import { useDispatch, useSelector } from "../../../store/reduxHooks";
import { useEditScreenContext } from "../EditScreen/EditScreenProvider";
import { getDefaultRoiCoords } from "./functions";
import { FC, HTMLProps } from "react";
import styles from "./EditRoiForm.module.scss";
import classNames from "classnames";

export type Props = {
  formik: any;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const EditRoiForm: FC<Props> = (props) => {
  const { formik, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const dispatch = useDispatch();
  const { roiCoords } = useSelector((state) => state.edit);
  const { imageParams, editImageRef } = useEditScreenContext();
  const { width, height } = imageParams;

  const addROI = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setRoiCoords(getDefaultRoiCoords(width, height)));
    if (editImageRef.current) {
      editImageRef.current.scrollIntoView();
    }
  };

  return (
    <div className={className} {...props}>
      <h3>Head detection parameters</h3>
      <Button
        variant="contained"
        onClick={addROI}
        disabled={!!roiCoords}
        className={styles["add-roi-btn"]}
      >
        Add ROI
      </Button>
      <TextField
        type="number"
        inputProps={{ min: 0 }}
        label="min head height px"
        id="head_detection_parameters.min_head_height_px"
        name="head_detection_parameters.min_head_height_px"
        value={formik.values.head_detection_parameters.min_head_height_px}
        onChange={(e) =>
          formik.setFieldValue(
            "head_detection_parameters.min_head_height_px",
            +e.target.value
          )
        }
      />
      <TextField
        type="number"
        inputProps={{ min: 0 }}
        label="max head height px"
        id="head_detection_parameters.max_head_height_px"
        name="head_detection_parameters.max_head_height_px"
        value={formik.values.head_detection_parameters.max_head_height_px}
        onChange={(e) =>
          formik.setFieldValue(
            "head_detection_parameters.max_head_height_px",
            +e.target.value
          )
        }
      />
      <TextField
        type="number"
        inputProps={{ step: "any", min: 0 }}
        label="confidence threshold"
        id="head_detection_parameters.confidence_threshold"
        name="head_detection_parameters.confidence_threshold"
        value={formik.values.head_detection_parameters.confidence_threshold}
        onChange={(e) =>
          formik.setFieldValue(
            "head_detection_parameters.confidence_threshold",
            +e.target.value
          )
        }
      />
    </div>
  );
};

export default EditRoiForm;
