import { bbox } from "../../store/reducers/cameraReducer";
import { decodeImageToString, getFrame } from "../../utils/functions";
import { getPerpendicularCoords } from "../EditCamera/EditSignalLine/Arrow";

export const toDataURL = async (
  setImage: (value: { [key: string]: string }) => void,
  selectedCamId: string,
  setError: (val: boolean) => void
) => {
  try {
    const res = await getFrame(selectedCamId); // получить текущий кадр камеры
    const blob = await res.blob(); // преобразовать изображение в blob
    await decodeImageToString(setImage, selectedCamId, blob); // преобразовать переданный blob в base64 строку и сохранить как image
  } catch (e) {
    setError(true);
  }
};

export const createEventThresholdLines = (
  [x1, y1, x2, y2]: number[],
  distance: number,
  setLine1Coords: (coords: bbox) => void,
  setLine2Coords: (coords: bbox) => void
) => {
  for (let i = 0; i < 2; i++) {
    const direction = i == 0 ? 1 : -1;
    const { px: l_x1, py: l_y1 } = getPerpendicularCoords(
      x1,
      x2,
      y1,
      y2,
      direction,
      distance,
      1
    );
    const { px: l_x2, py: l_y2 } = getPerpendicularCoords(
      x2,
      x1,
      y2,
      y1,
      direction,
      distance,
      1
    );
    switch (i) {
      case 0:
        setLine1Coords([l_x1, l_y1, l_x2, l_y2]);
        break;
      case 1:
        setLine2Coords([l_x1, l_y1, l_x2, l_y2]);
    }
  }
};
