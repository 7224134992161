import useEditRoi from "./useEditRoi";
import RoiMask from "./RoiMask";
import EditPoint from "./EditPoint";
import DeleteRoi from "./DeleteRoi";

const EditRoi = ({ svgRef }: { svgRef: React.RefObject<SVGSVGElement> }) => {
  const { imageParams, ROI, handlePointChange, updateROI, deleteROI } =
    useEditRoi();

  return (
    <>
      {ROI && (
        <>
          <RoiMask
            imgWidth={imageParams.width}
            imgHeight={imageParams.height}
            id="EDIT_ROI"
            roiCoords={ROI}
          />
          <DeleteRoi p={[ROI[2], ROI[1]]} onClick={deleteROI} />
          <EditPoint
            p={[ROI[0], ROI[1]]}
            handlePointChange={(x, y) => handlePointChange(x, y, 0)}
            handlePointSet={updateROI}
            svgRef={svgRef}
          />
          <EditPoint
            p={[ROI[2], ROI[3]]}
            handlePointChange={(x, y) => handlePointChange(x, y, 1)}
            handlePointSet={updateROI}
            svgRef={svgRef}
          />
        </>
      )}
    </>
  );
};

export default EditRoi;
