import { useEffect, useState, useRef } from "react";
import { useEditProvider } from "../EditProvider";
import { setCameraList } from "../../../store/reducers/cameraReducer";
import { useDispatch, useSelector } from "../../../store/reduxHooks";
import {
  createLine,
  getAllCameras,
  updateLine,
} from "../../../utils/functions";

const useEditLine = () => {
  const {
    SL_Coords,
    setSL_Coords,
    SL_Name,
    setSL_Name,
    SL_Directions,
    setSL_Directions,
    SL_EGP,
    setSL_EGP,
    SL_MarkOutDirection,
    imageParams,
    wrapperParams,
    formDistanceThreshold,
    setSl_Dt_OutOfBoundary,
  } = useEditProvider();
  const dispatch = useDispatch();
  const [signallineId, setSignallineId] = useState("");

  const { editCameraId, cameraList } = useSelector((state) => state.camera);
  const [lineExist, setLineExist] = useState(false);

  const handleLineSafe = async () => {
    const body = {
      coordinates: Object.values(SL_Coords),
      name: SL_Name,
      event_generation_parameters: SL_EGP,
      directions: SL_Directions,
    };
    try {
      // создать/обновить сигнальную линию если не/существует
      if (lineExist) {
        await updateLine(signallineId, body);
      } else {
        await createLine(editCameraId, body);
      }
      // получить список камер с обновленными сигнальными линиями
      const cameras = await getAllCameras();
      // установить обновленный список камер
      dispatch(setCameraList(cameras));
    } catch (e) {
      console.log("Error while updating signal line");
    }
  };

  useEffect(() => {
    const { x1, y1, x2, y2 } = SL_Coords;
    if (!imageParams) return;
    if (
      !(x1 + y1 + x2 + y2) || // сигнальная линия не отмечена
      x2 > imageParams.width || // сигнальная линия выходит за пределы картинки
      x1 > imageParams.width
    ) {
      if (!imageParams) return;
      // установить дефолтную сигнальную линию
      setSL_Coords({
        x1: 0,
        y1: imageParams.height / 2,
        x2: imageParams.width,
        y2: imageParams.height / 2,
      });
    }
  }, [imageParams]);

  // нарисовать соответствующую камере сигнальную линию
  useEffect(() => {
    const camera = cameraList.filter((cam) => cam.id === editCameraId)[0];
    if (camera && camera.signal_lines) {
      const firstLine = Object.values(camera.signal_lines)[0];
      const { name, directions, id, event_generation_parameters } = firstLine;
      setSL_Name(name);
      setSL_Directions(directions);
      setSL_EGP(event_generation_parameters);
      setSignallineId(id);
      const [x1, y1, x2, y2] = firstLine.coordinates.map(
        (coord) => Math.trunc(coord) // убрать знаки после запятой
      );
      setSL_Coords({ x1, y1, x2, y2 });
      setLineExist(true);
    }
  }, [editCameraId]);

  return {
    wrapperParams,
    imageParams,
    SL_Coords,
    SL_MarkOutDirection,
    SL_EGP,
    formDistanceThreshold,
    setSL_EGP,
    setSL_Coords,
    handleLineSafe,
    setSl_Dt_OutOfBoundary,
  };
};

export default useEditLine;
