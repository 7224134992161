import axios from "axios";
import {
  CameraWriteObject,
  CameraReadObject,
} from "../store/reducers/cameraReducer";

const { API_URL } = window;

export const getInitCamId = () => {
  let id = localStorage.getItem("current_cam_id");
  return id;
};

export const createCamera: (
  camera: CameraWriteObject
) => Promise<CameraReadObject> = async (camera) => {
  try {
    const res = await fetch(`${API_URL}/cameras`, {
      headers: {
        "Content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(camera),
    });
    const data = await res.json();
    return data;
  } catch (err) {}
};

export const editCamera: (
  cameraParams: Object,
  camId: string
) => Promise<CameraWriteObject> = async (cameraParams, cam_id) => {
  try {
    const res = await fetch(`${API_URL}/cameras/${cam_id}`, {
      headers: {
        "Content-type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify(cameraParams),
    });
    const data = await res.json();
    return data;
  } catch (err) {}
};

export const getAllCameras = async () => {
  try {
    const res = await fetch(`${API_URL}/cameras`);
    const data = await res.json();
    return data.cameras;
  } catch (err) {
    console.log(err);
  }
};

export const getReadOnlyRights = async () => {
  try {
    const res = await fetch(`${API_URL}/config`);
    const data = await res.json();
    return data.read_only;
  } catch (e) {
    console.log("Could not get read-only rights");
    console.log(e);
  }
};

export const deleteCamera = async (id: string) => {
  try {
    await fetch(`${API_URL}/cameras/${id}`, { method: "DELETE" });
  } catch (err) {
    console.log(err);
  }
};

export const getCameraById: (
  id: string,
  cameraList: CameraReadObject[]
) => CameraReadObject = (id, cameraList) => {
  return cameraList.filter((cam) => cam.id === id)[0];
};

export const updateLine = async (s_id: string, body: any) => {
  try {
    await axios({
      url: `${API_URL}/signal_lines/${s_id}`,
      method: "PATCH",
      data: body,
    });
  } catch (e) {
    console.log(e);
  }
};

export const createLine = async (c_id: string, body: any) => {
  try {
    await axios({
      url: `${API_URL}/cameras/${c_id}/signal_lines`,
      method: "POST",
      data: body,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getFrame = async (camId: string) => {
  return fetch(`${API_URL}/cameras/${camId}/frame`).then((response) => {
    if (!response.ok) {
      throw new Error("No Image");
    }
    return response;
  });
};

export const decodeImageToString = async (
  setImage: (value: any) => void,
  camId: string,
  blob: Blob
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage((prev: any) => {
        return { ...prev, [`${camId}`]: reader.result };
      });
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

type loadImageFunction = (
  setImageParams: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => void,
  url: string
) => void;

export const getImageSize: loadImageFunction = async (setImageParams, url) => {
  const image = new Image();
  image.onload = (e) => {
    setImageParams({ width: image.width, height: image.height });
  };
  image.src = url;
};

export const tryToGetImage = async (
  url: string,
  setImageHref: (href: string) => void,
  setImageParams?: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => void,
  tries = 0
) => {
  if (tries > 20) {
    // показать NoImage, если 20 раз не удалось получить картинку
    setImageHref("NoImage");
    return;
  }
  tries += 1;
  const image = new Image();
  image.onload = (e) => {
    setImageHref(image.src); // state, чтобы вызвать ререндер image и подгрузить картинку
    if (setImageParams) {
      setImageParams({ width: image.width, height: image.height });
    }
  };
  image.onerror = () => {
    // повторить загрузку картинки через 0.5сек
    setTimeout(
      () => tryToGetImage(url, setImageHref, setImageParams, tries),
      500
    );
  };
  image.src = url;
};

export type Lang = "ru" | "en";

export function isLang(lang: string | null): lang is Lang {
  return lang === "ru" || lang === "en";
}

export const formatDate = (timestamp: string) => {
  const formatted = new Date(timestamp);
  const date = formatted.toLocaleDateString("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const time = formatted.toLocaleTimeString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return { date, time };
};
