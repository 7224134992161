import { Button, Backdrop, TextField, CircularProgress } from "@mui/material";
import ClickOutsideComponent from "../ClickOutsideComponent";
import useAddCamera from "../../utils/hooks/useAddCamera";
import {
  setCameraList,
  setEditCameraId,
  setShowAddCameraForm,
} from "../../store/reducers/cameraReducer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createCamera } from "../../utils/functions";
import useNotify from "../../utils/hooks/useNotify";
import classNames from "classnames";
import styles from "./AddCamera.module.scss";

const AddCameraForm = () => {
  const { loads, showAddCameraForm, dispatch, setLoads, cameraList } =
    useAddCamera();
  const className = classNames([styles["container"]], {});
  const { notify } = useNotify();

  const formik = useFormik({
    initialValues: {
      name: "",
      video_url: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),
      video_url: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      const newCamera = JSON.parse(JSON.stringify(values));
      try {
        setLoads(true);
        // сохранить камеру
        const returnedCameraData = await createCamera(newCamera);
        // обновить текущий список камер
        dispatch(setCameraList([...cameraList, returnedCameraData]));
        dispatch(setShowAddCameraForm(false));
        // открыть окно редактирования только что созданной камеры
        dispatch(setEditCameraId(returnedCameraData.id));
        formik.resetForm();
      } catch (err) {
        notify("Error adding camera", "error");
      } finally {
        setLoads(false);
      }
    },
  });

  return (
    <Backdrop open={showAddCameraForm} style={{ zIndex: 100 }}>
      <ClickOutsideComponent
        foo={() => {
          dispatch(setShowAddCameraForm(false));
          formik.resetForm();
        }}
      >
        <form className={className} onSubmit={formik.handleSubmit}>
          <span className={"T1"}>Create a new camera</span>
          <TextField
            error={!!formik.touched.name && !!formik.errors.name}
            label="name"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik.touched.name && formik.errors.name && (
            <span className={styles["error"]}>{formik.errors.name}</span>
          )}
          <TextField
            error={!!formik.touched.video_url && !!formik.errors.video_url}
            label="video_url"
            id="video_url"
            name="video_url"
            value={formik.values.video_url}
            onChange={formik.handleChange}
          />
          {formik.touched.video_url && formik.errors.video_url && (
            <span className={styles["error"]}>{formik.errors.video_url}</span>
          )}
          <div className={styles["buttons-container"]}>
            {loads ? (
              <CircularProgress />
            ) : (
              <>
                <Button variant="contained" type="submit" color="success">
                  Continue
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    formik.resetForm();
                    dispatch(setShowAddCameraForm(false));
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </form>
      </ClickOutsideComponent>
    </Backdrop>
  );
};

export default AddCameraForm;
