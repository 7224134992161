import i18next from "i18next";
import common_ru from "./ru/common.json";
import common_en from "./en/common.json";
import { isLang, Lang } from "../utils/functions";

export default function init18Next() {
  const savedLanguage = localStorage.getItem("lang");
  const current: Lang = isLang(savedLanguage) ? savedLanguage : "en";
  i18next.init({
    interpolation: { escapeValue: false },
    lng: current,
    resources: {
      en: {
        common: common_en,
      },
      ru: {
        common: common_ru,
      },
    },
  });
}
