import NoImage from "../../../assets/no_image.png";
import { CircularProgress } from "@mui/material";
import useEditScreen from "./useEditScreen";
import EditSignalLine from "../EditSignalLine";
import EditRoi from "../EditRoi";
import { useRef } from "react";

import { FC, HTMLProps } from "react";
import styles from "./EditScreen.module.scss";
import classNames from "classnames";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const EditScreen: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const { imageHref, wrapperRef, imageParams, imageRef, editImageRef } =
    useEditScreen();

  const svgRef = useRef<SVGSVGElement>(null);

  return (
    <div className={className} {...props} ref={editImageRef}>
      {!imageHref && <CircularProgress />}
      {imageHref == "NoImage" && (
        <img src={NoImage} className={styles["no-img"]} alt="" />
      )}
      {imageHref && imageHref !== "NoImage" && (
        <div ref={wrapperRef} className={styles["video-container"]}>
          <svg
            ref={svgRef}
            viewBox={`0 0 ${imageParams.width} ${imageParams.height}`}
          >
            {/* картинка, поверх которой отрисовываются настройки линии и roi */}
            <image ref={imageRef} href={imageHref} />
            <EditRoi svgRef={svgRef} />
            <EditSignalLine svgRef={svgRef} />
          </svg>
        </div>
      )}
    </div>
  );
};

export default EditScreen;
