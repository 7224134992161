import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { store } from "./store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalScrollStyles from "./theme/globalScrollBarStyles";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18nextInit from "./translations/index";

const isMac = navigator.platform.includes("Mac");

declare global {
  interface Window {
    SENTRY_DSN: string;
    SENTRY_TRACES_RATE: string;
    API_URL: string;
    SENTRY_ENVIRONMENT: string;
    YANDEX_METRIC_ID: string;
  }
}

const { SENTRY_DSN, SENTRY_TRACES_RATE, SENTRY_ENVIRONMENT } = window;

i18nextInit();

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: +SENTRY_TRACES_RATE,
  environment: SENTRY_ENVIRONMENT,
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Router>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            {!isMac && <GlobalScrollStyles />}
            <App />
          </ThemeProvider>
        </Provider>
      </Router>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
