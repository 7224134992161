import { configureStore } from "@reduxjs/toolkit";
import cameraReducer from "./reducers/cameraReducer";
import notificationReducer from "./reducers/notificationReducer";
import eventsReducer from "./reducers/eventsReducer";
import editReducer from "./reducers/editReducer";

export const store = configureStore({
  reducer: {
    camera: cameraReducer,
    notification: notificationReducer,
    events: eventsReducer,
    edit: editReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
