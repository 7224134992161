import styles from "./Navigation.module.scss";
import Logo from "../Logo/Logo";
import Description from "../Description";
import { useEffect, useState } from "react";
import Language from "../Language";
import { useTranslation } from "react-i18next";
import { FC, HTMLProps } from "react";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const Navigation: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const { i18n } = useTranslation();
  const [descriptionOpened, setDescriptionOpened] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchParams] = useSearchParams();
  const camId = searchParams.get("camId");

  const toggleDescription = () => {
    setDescriptionOpened((prev) => !prev);
  };

  useEffect(() => {
    if (camId) {
      setTimeout(() => {
        setDescriptionOpened(false);
      }, 7000);
    }
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (firstLoad) return;
    if (camId) {
      setDescriptionOpened(false);
    }
  }, [camId]);

  return (
    <div className={className} {...props}>
      <Logo addClasses={[styles["logo"]]} handleClick={toggleDescription} />
      <div className={styles["lang-container"]}>
        <Language
          title="Ru"
          onClick={() => i18n.changeLanguage("ru")}
          selected={i18n.language.includes("ru")}
        />
        <Language
          title="En"
          onClick={() => i18n.changeLanguage("en")}
          selected={i18n.language === "en"}
        />
      </div>
      <Description
        opened={descriptionOpened}
        addClasses={[styles["description"]]}
      />
    </div>
  );
};

export default Navigation;
