import { FC, HTMLProps, useState } from "react";
import styles from "./CropsList.module.scss";
import classNames from "classnames";
import useEvent from "../../utils/hooks/useEvent";
import { EventType } from "../../store/reducers/eventsReducer";
import Crop from "../Crop";
import CropPreview from "../Crop/Preview";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const CropsList: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const { eventsList } = useEvent();
  const [previewEvent, setPreviewEvent] = useState<EventType>();
  const [showCropPreview, setShowCropPreview] = useState(false);

  return (
    <>
      <div className={className} {...props}>
        {eventsList.map((event, i) => {
          if (event.id) {
            return (
              <Crop
                key={i}
                event={event}
                onClick={() => {
                  setPreviewEvent(event);
                  setShowCropPreview(true);
                }}
              />
            );
          }
          return null;
        })}
      </div>
      <CropPreview
        event={previewEvent}
        open={showCropPreview}
        close={() => {
          setShowCropPreview(false);
        }}
      />
    </>
  );
};

export default CropsList;
