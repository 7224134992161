import { FC, HTMLProps, useEffect, useLayoutEffect, useRef } from "react";
import styles from "./Description.module.scss";
import classNames from "classnames";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

export type Props = {
  opened: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Description: FC<Props> = (props) => {
  const tl = useRef<gsap.core.Timeline>();
  const [t] = useTranslation("common");
  const containerRef = useRef<HTMLDivElement>(null);
  const { opened, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    { [styles["closed"]]: !opened }
  );

  useLayoutEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current.set(containerRef.current, {});
    tl.current.to(containerRef.current, {
      marginTop: 0,
      height: 0,
    });
  }, []);

  useEffect(() => {
    !opened ? tl.current?.play() : tl.current?.reverse();
  }, [opened]);

  return (
    <div className={className} {...props} ref={containerRef}>
      <span>{t("demo.description_1")}</span>
      <br />
      <br />
      <span>{t("demo.description_2")}</span>
      <ul className={styles["bullets"]}>
        <li>{t("demo.bullet_1")}</li>
        <li>{t("demo.bullet_2")}</li>
        <li>{t("demo.bullet_3")}</li>
      </ul>
    </div>
  );
};

export default Description;
