import AddCameraForm from "./AddCameraForm";

const AddCamera = () => {
  return (
    <div>
      <AddCameraForm />
    </div>
  );
};

export default AddCamera;
