import { useEffect, useState } from "react";
import { getCameraById } from "../functions";
import useCamera from "./useCamera";
import { EventType } from "../../store/reducers/eventsReducer";
import { useSelector, useDispatch } from "../../store/reduxHooks";
import { setEvents } from "../../store/reducers/eventsReducer";

const useCounter = () => {
  const { eventsList } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const { selectedCamId, cameraList } = useCamera();
  const { readonly } = useSelector((state) => state.camera);
  const [directionsNames, setDirectionsNames] = useState({
    rightName: "Entry",
    leftName: "Exit",
  });

  const resetCounter = () => {
    setLeft(0);
    setRight(0);
    dispatch(setEvents([]));
  };

  useEffect(() => {
    if (eventsList[0]) {
      const { direction } = eventsList[0];
      if (direction === "left") {
        setLeft((prev) => (prev += 1));
      } else {
        setRight((prev) => (prev += 1));
      }
    }
  }, [eventsList]);

  useEffect(() => {
    const selectedCamera = getCameraById(selectedCamId, cameraList);
    const signalLinesArray =
      selectedCamera && selectedCamera.signal_lines
        ? Object.values(selectedCamera.signal_lines)
        : [];
    if (selectedCamera && signalLinesArray.length) {
      const { left, right } = signalLinesArray[0].directions;
      setDirectionsNames({ rightName: right.name, leftName: left.name });
    }
    resetCounter();
  }, [cameraList, selectedCamId]);

  return { left, right, directionsNames, resetCounter, readonly };
};

export default useCounter;
