import { FC, HTMLProps } from "react";
import styles from "./BottomSection.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const BottomSection: FC<Props> = (props) => {
  const [t] = useTranslation("common");
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  return (
    <div className={styles["wrapper"]}>
      <div className={className} {...props}>
        <span>
          © 2010-{new Date().getFullYear()}.{" "}
          <a href="https://tevian.ru/" rel="noreferrer" target="_blank">
            Tevian
          </a>
        </span>
        <a href="/terms.pdf" target="_blank" rel="noreferrer">
          {t("policy")}
        </a>
      </div>
    </div>
  );
};

export default BottomSection;
