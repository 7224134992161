import { useEffect, useRef } from "react";

type ClickOutsideComponentProps = {
  children: React.ReactChild;
  // функция, которая будет выполняться по клику вне компонента
  foo: () => void;
};

function useOutsideAlerter(
  ref: React.RefObject<HTMLDivElement>,
  foo: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event!.target)) {
        foo();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, foo]);
}

// оборачивание этим компонентом добавляет оборачиваему компоненту слушателя
// клика вне компонента
const ClickOutsideComponent = (props: ClickOutsideComponentProps) => {
  const { children, foo } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, foo);
  return <div ref={wrapperRef}>{children}</div>;
};

export default ClickOutsideComponent;
