import { InputAdornment, TextField } from "@mui/material";
import styles from "./EditSignalLine.module.scss";
import { useEditProvider } from "../EditProvider";
import { FC, HTMLProps } from "react";
import classNames from "classnames";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const EditSignalLineForm: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames([addClasses && [...addClasses]], {});

  const darkCircleClass = classNames([styles["circle"], styles["circle-dark"]]);

  const {
    SL_Name,
    setSL_Name,
    SL_Directions,
    setSL_Directions,
    SL_EGP,
    setSL_EGP,
    setSL_MarkOutDirection,
    setFormDistanceThreshold,
    Sl_Dt_OutOfBoundary,
  } = useEditProvider();

  return (
    <div className={className} {...props}>
      <h3>Signal Line</h3>
      <TextField
        required
        label="name"
        value={SL_Name}
        onChange={(e) => setSL_Name(e.target.value)}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="direction left"
        value={SL_Directions.left.name}
        onChange={(e) => {
          setSL_Directions({
            ...SL_Directions,
            left: { name: e.target.value },
          });
        }}
        onFocus={() => {
          setSL_MarkOutDirection("left");
        }}
        onBlur={() => {
          setSL_MarkOutDirection(null);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div className={darkCircleClass} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="direction right"
        value={SL_Directions.right.name}
        onChange={(e) => {
          setSL_Directions({
            ...SL_Directions,
            right: { name: e.target.value },
          });
        }}
        onFocus={() => {
          setSL_MarkOutDirection("right");
        }}
        onBlur={() => {
          setSL_MarkOutDirection(null);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div className={styles["circle"]} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        required
        type="number"
        inputProps={{ step: "any", min: 0 }}
        label="distance threshold"
        value={SL_EGP.distance_threshold_px}
        onChange={(e) => {
          const newValue = +e.target.value;
          if (Sl_Dt_OutOfBoundary && newValue >= SL_EGP.distance_threshold_px)
            return;
          setFormDistanceThreshold(newValue);
          setSL_EGP({ distance_threshold_px: newValue });
        }}
        error={Sl_Dt_OutOfBoundary}
        InputLabelProps={{ shrink: true }}
      />
      {Sl_Dt_OutOfBoundary && (
        <span style={{ color: "red" }}>
          Distance is out of camera boundaries
        </span>
      )}
    </div>
  );
};

export default EditSignalLineForm;
