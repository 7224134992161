import Masonry from "@mui/lab/Masonry";
import { useFormik } from "formik";
import useAddCamera from "../../utils/hooks/useAddCamera";
import * as Yup from "yup";
import { editCamera, getAllCameras } from "../../utils/functions";
import { setCameraList, setVideoUrl } from "../../store/reducers/cameraReducer";
import useNotify from "../../utils/hooks/useNotify";
import EditSignalLineForm from "./EditSignalLine/EditSignalLineForm";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useSelector } from "../../store/reduxHooks";
import EditRoiForm from "./EditRoi/EditRoiForm";
import { useEditProvider } from "./EditProvider";
import { FC, HTMLProps } from "react";
import styles from "./EditCamera.module.scss";
import classNames from "classnames";

export type Props = {
  handleSafe: () => void;
  addClasses?: string[];
} & HTMLProps<HTMLFormElement>;

const EditCameraForm: FC<Props> = (props) => {
  const { handleSafe, addClasses } = props;
  const className = classNames(
    [styles["form-container"], addClasses && [...addClasses]],
    {}
  );

  const { notify } = useNotify();
  const { roiCoords } = useSelector((state) => state.edit);
  const { loads, setLoads, editCameraId, dispatch, cameraList } =
    useAddCamera();
  const { Sl_Dt_OutOfBoundary } = useEditProvider();
  const camera = cameraList.filter((cam) => cam.id === editCameraId)[0];

  const formik = useFormik({
    initialValues: {
      name: camera?.name,
      video_url: camera?.video_url,
      use_video_timestamps: camera?.use_video_timestamps,
      event_callback: {
        url: camera?.event_callback.url,
        send_crop: camera?.event_callback.send_crop,
        send_frame: camera?.event_callback.send_frame,
      },
      crop_expand: {
        horizontal: +camera?.crop_expand.horizontal,
        top: +camera?.crop_expand.top,
        bottom: +camera?.crop_expand.bottom,
      },
      head_detection_parameters: {
        min_head_height_px:
          +camera?.head_detection_parameters.min_head_height_px,
        max_head_height_px:
          camera?.head_detection_parameters.max_head_height_px || 0,
        roi: camera?.head_detection_parameters.roi || [0, 0, 0, 0],
        confidence_threshold:
          +camera?.head_detection_parameters.confidence_threshold,
      },
      tracking_parameters: {
        detection_interval_secs:
          camera?.tracking_parameters?.detection_interval_secs || 0,
        visual_tracking_estimate_delta_secs:
          camera?.tracking_parameters?.visual_tracking_estimate_delta_secs || 0,
        max_not_tracked_secs:
          camera?.tracking_parameters?.max_not_tracked_secs || 0,
        bbox_smoothing_factor:
          camera?.tracking_parameters?.bbox_smoothing_factor || 0,
        matching_head_bbox_scale_factor:
          camera?.tracking_parameters?.matching_head_bbox_scale_factor || 0,
        matching_iou_threshold:
          camera?.tracking_parameters?.matching_iou_threshold || 0,
      },
      event_generation_parameters: {
        track_extrapolation_secs:
          +camera?.event_generation_parameters.track_extrapolation_secs,
        min_interval_between_events_secs:
          +camera?.event_generation_parameters.min_interval_between_events_secs,
      },
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),
      video_url: Yup.string().required("Required"),
      url: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      const newCamera = JSON.parse(
        JSON.stringify({
          ...values,
          head_detection_parameters: {
            ...values.head_detection_parameters,
            roi: roiCoords,
          },
        })
      );
      try {
        setLoads(true);
        // сохранить измененные значения камеры
        const returnedCameraData = await editCamera(newCamera, editCameraId);
        // сохранить сигнальную линию и отобразить успешность сохранения
        await handleSafe();
        // получить список камер с обновленными данными
        const allCameras = await getAllCameras();
        // обновить источник видео, если он был изменен
        dispatch(setVideoUrl(returnedCameraData.video_url));
        // обновить список камер
        dispatch(setCameraList(allCameras));
      } catch (err) {
        notify("Error editing camera", "error");
      } finally {
        setLoads(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={className} {...props}>
      <h1 style={{ paddingBottom: 20 }}>Edit Camera {camera?.name}</h1>
      <Masonry columns={{ sm: 1, md: 2, lg: 3 }} spacing={2}>
        <EditSignalLineForm addClasses={[styles["form-group"]]} />
        <div className={styles["form-group"]}>
          <h3>Main</h3>
          <TextField
            error={!!formik.touched.name && !!formik.errors.name}
            label="name"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: true }}
          />
          {formik.touched.name && formik.errors.name ? (
            <span className={styles["error"]}>{formik.errors.name}</span>
          ) : null}
          <TextField
            error={!!formik.touched.video_url && !!formik.errors.video_url}
            label="video url"
            id="video_url"
            name="video_url"
            value={formik.values.video_url}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: true }}
          />
          {formik.touched.video_url && formik.errors.video_url ? (
            <span className={styles["error"]}>{formik.errors.video_url}</span>
          ) : null}
          <FormControlLabel
            control={
              <Checkbox
                id="use_video_timestamps"
                name="use_video_timestamps"
                checked={formik.values.use_video_timestamps}
                onChange={formik.handleChange}
              />
            }
            label="use video timestamps"
          />
        </div>

        <div className={styles["form-group"]}>
          <h3>Event Callback</h3>
          <TextField
            error={
              !!formik.touched.event_callback?.url &&
              !!formik.errors.event_callback?.url
            }
            label="url"
            id="url"
            name="url"
            value={formik.values.event_callback.url || null}
            onChange={(e) => {
              const newUrl = e.target.value || null;
              formik.setFieldValue("event_callback", {
                url: newUrl,
                send_crop: newUrl
                  ? formik.values.event_callback.send_crop
                  : false,
                send_frame: newUrl
                  ? formik.values.event_callback.send_frame
                  : false,
              });
            }}
          />
          {formik.touched.event_callback?.url &&
            formik.errors.event_callback?.url && (
              <span className={styles["error"]}>
                {formik.errors.event_callback.url}
              </span>
            )}
          <FormControlLabel
            control={
              <Checkbox
                id="send_crop"
                name="send_crop"
                checked={formik.values.event_callback.send_crop}
                disabled={!formik.values.event_callback.url}
                onChange={(e) => {
                  formik.setFieldValue("event_callback", {
                    ...formik.values.event_callback,
                    send_crop: e.target.checked,
                  });
                }}
              />
            }
            label="send crop"
          />
          <FormControlLabel
            control={
              <Checkbox
                id="send_frame"
                name="send_frame"
                checked={formik.values.event_callback.send_frame}
                disabled={!formik.values.event_callback.url}
                onChange={(e) => {
                  formik.setFieldValue("event_callback", {
                    ...formik.values.event_callback,
                    send_frame: e.target.checked,
                  });
                }}
                sx={{ py: 0 }}
              />
            }
            label="send frame"
          />
        </div>

        <div className={styles["form-group"]}>
          <h3>Crop Expand</h3>
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="horizontal"
            id="crop_expand.horizontal"
            name="crop_expand.horizontal"
            value={formik.values.crop_expand.horizontal}
            onChange={(e) =>
              formik.setFieldValue("crop_expand.horizontal", +e.target.value)
            }
          />
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="top"
            id="crop_expand.top"
            name="crop_expand.top"
            value={formik.values.crop_expand.top}
            onChange={(e) =>
              formik.setFieldValue("crop_expand.top", +e.target.value)
            }
          />
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="bottom"
            id="crop_expand.bottom"
            value={formik.values.crop_expand.bottom}
            onChange={(e) =>
              formik.setFieldValue("crop_expand.bottom", +e.target.value)
            }
          />
        </div>
        <EditRoiForm formik={formik} />
        <div className={styles["form-group"]}>
          <h3>Tracking parameters</h3>
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="detection interval secs"
            id="tracking_parameters.detection_interval_secs"
            name="tracking_parameters.detection_interval_secs"
            value={formik.values.tracking_parameters.detection_interval_secs}
            onChange={(e) =>
              formik.setFieldValue(
                "tracking_parameters.detection_interval_secs",
                +e.target.value
              )
            }
          />
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="visual tracking estimate delta secs"
            id="tracking_parameters.visual_tracking_estimate_delta_secs"
            name="tracking_parameters.visual_tracking_estimate_delta_secs"
            value={
              formik.values.tracking_parameters
                .visual_tracking_estimate_delta_secs
            }
            onChange={(e) =>
              formik.setFieldValue(
                "tracking_parameters.visual_tracking_estimate_delta_secs",
                +e.target.value
              )
            }
          />
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="max not tracked secs"
            id="tracking_parameters.max_not_tracked_secs"
            name="tracking_parameters.max_not_tracked_secs"
            value={formik.values.tracking_parameters.max_not_tracked_secs}
            onChange={(e) =>
              formik.setFieldValue(
                "tracking_parameters.max_not_tracked_secs",
                +e.target.value
              )
            }
          />
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="bbox smoothing factor"
            id="tracking_parameters.bbox_smoothing_factor"
            name="tracking_parameters.bbox_smoothing_factor"
            value={formik.values.tracking_parameters.bbox_smoothing_factor}
            onChange={(e) =>
              formik.setFieldValue(
                "tracking_parameters.bbox_smoothing_factor",
                +e.target.value
              )
            }
          />
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="matching head bbox scale factor"
            id="tracking_parameters.matching_head_bbox_scale_factor"
            name="tracking_parameters.matching_head_bbox_scale_factor"
            value={
              formik.values.tracking_parameters.matching_head_bbox_scale_factor
            }
            onChange={(e) =>
              formik.setFieldValue(
                "tracking_parameters.matching_head_bbox_scale_factor",
                +e.target.value
              )
            }
          />
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="matching intersection over union threshold"
            id="tracking_parameters.matching_iou_threshold"
            name="tracking_parameters.matching_iou_threshold"
            value={formik.values.tracking_parameters.matching_iou_threshold}
            onChange={(e) =>
              formik.setFieldValue(
                "tracking_parameters.matching_iou_threshold",
                +e.target.value
              )
            }
          />
        </div>

        <div className={styles["form-group"]}>
          <h3>Event generation parameters</h3>
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="track extrapolation secs"
            id="event_generation_parameters.track_extrapolation_secs"
            name="event_generation_parameters.track_extrapolation_secs"
            value={
              formik.values.event_generation_parameters.track_extrapolation_secs
            }
            onChange={(e) =>
              formik.setFieldValue(
                "event_generation_parameters.track_extrapolation_secs",
                +e.target.value
              )
            }
          />
          <TextField
            type="number"
            inputProps={{ step: "any", min: 0 }}
            label="min interval between events secs"
            id="event_generation_parameters.min_interval_between_events_secs"
            name="event_generation_parameters.min_interval_between_events_secs"
            value={
              formik.values.event_generation_parameters
                .min_interval_between_events_secs
            }
            onChange={(e) =>
              formik.setFieldValue(
                "event_generation_parameters.min_interval_between_events_secs",
                +e.target.value
              )
            }
          />
        </div>
      </Masonry>
      <div className={styles["buttons-container"]}>
        {loads ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            type="submit"
            disabled={Sl_Dt_OutOfBoundary}
          >
            Save camera
          </Button>
        )}
      </div>
    </form>
  );
};

export default EditCameraForm;
