import { bbox } from "../../../store/reducers/cameraReducer";

type Props = {
  imgWidth: number;
  imgHeight: number;
  roiCoords: bbox | null;
  id: string;
};

const RoiMask = ({ imgWidth, imgHeight, roiCoords, id }: Props) => {
  if (!roiCoords) return null;
  return (
    <svg
      viewBox={`0 0 ${imgWidth} ${imgHeight}`}
      style={{ position: "absolute", zIndex: 100 }}
    >
      {/* https://stackoverflow.com/questions/40423305/is-it-possible-to-invert-the-clipping-of-svg */}
      <defs>
        <mask id={id} x="0" y="0" width={imgWidth} height={imgHeight}>
          <rect width={imgWidth} height={imgHeight} fill="white" />
          <rect
            x={roiCoords[0]}
            y={roiCoords[1]}
            width={Math.abs(roiCoords[0] - roiCoords[2])}
            height={Math.abs(roiCoords[1] - roiCoords[3])}
            fill="black"
          />
        </mask>
      </defs>
      <rect
        x="0"
        y="0"
        width={imgWidth}
        height={imgHeight}
        fill="black"
        fillOpacity={0.5}
        mask={`url(#${id})`}
      />
    </svg>
  );
};

export default RoiMask;
