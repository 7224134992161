import React, { useRef, useState } from "react";

type Props = {
  children: React.ReactNode;
};

type Default = {
  imageParams: { width: number; height: number };
  setImageParams: React.Dispatch<
    React.SetStateAction<{
      width: number;
      height: number;
    }>
  >;
  wrapperParams: { width: number; height: number };
  setWrapperParams: React.Dispatch<
    React.SetStateAction<{
      width: number;
      height: number;
    }>
  >;
  editImageRef: React.MutableRefObject<any>;
};

const EditScreenContext = React.createContext({} as Default);

const EditScreenProvider = ({ children }: Props) => {
  const [imageParams, setImageParams] = useState({ width: 0, height: 0 });
  const [wrapperParams, setWrapperParams] = useState({ width: 0, height: 0 });
  const editImageRef = useRef(null);
  return (
    <EditScreenContext.Provider
      value={{
        imageParams,
        setImageParams,
        wrapperParams,
        setWrapperParams,
        editImageRef,
      }}
    >
      {children}
    </EditScreenContext.Provider>
  );
};

const useEditScreenContext = () => {
  const context = React.useContext(EditScreenContext);
  if (context === undefined) {
    throw new Error("useEditScreen must be used within a EditScreenProvider");
  }
  return context;
};

export { EditScreenProvider, useEditScreenContext };
