import { FC, HTMLProps, useCallback, useState } from "react";
import styles from "./Preview.module.scss";
import classNames from "classnames";
import ClickOutsideComponent from "../ClickOutsideComponent";
import { EventType } from "../../store/reducers/eventsReducer";
import { Backdrop } from "@mui/material";
import { getImageSize } from "../../utils/functions";

const { API_URL } = window;

export type Props = {
  open: boolean;
  event: EventType | undefined;
  close: () => void;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Preview: FC<Props> = (props) => {
  const { open, event, close, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const [imageParams, setImageParams] = useState({ width: 0, height: 0 });
  const imageRef = useCallback(
    (node: SVGImageElement) => {
      if (node !== null && event) {
        getImageSize(setImageParams, `${API_URL}/events/${event.id}/frame`);
      }
    },
    [event]
  );
  if (!event) return null;
  const { signal_line, body_bbox_on_frame, head_bbox_on_frame } = event;
  const [line_x1, line_y1, line_x2, line_y2] = signal_line.coordinates;
  const [body_x1, body_y1, body_width, body_height] = body_bbox_on_frame;
  const [head_x1, head_y1, head_width, head_height] = head_bbox_on_frame;

  return (
    <Backdrop open={open}>
      <ClickOutsideComponent foo={close}>
        <div className={className} {...props}>
          <svg viewBox={`0 0 ${imageParams.width} ${imageParams.height}`}>
            <image
              ref={imageRef}
              href={`${API_URL}/events/${event.id}/frame`}
            />
            <line
              x1={line_x1}
              y1={line_y1}
              x2={line_x2}
              y2={line_y2}
              stroke="red"
              strokeWidth={4}
            />
            <rect
              x={body_x1}
              y={body_y1}
              width={body_width}
              height={body_height}
              stroke={"blue"}
              strokeWidth={3}
              fill={"white"}
              fillOpacity={0}
            />
            <rect
              x={head_x1}
              y={head_y1}
              width={head_width}
              height={head_height}
              stroke={"red"}
              strokeWidth={3}
              fill={"white"}
              fillOpacity={0}
            />
          </svg>
        </div>
      </ClickOutsideComponent>
    </Backdrop>
  );
};

export default Preview;
