import { FC, HTMLProps } from "react";
import styles from "./CamNotSpecified.module.scss";
import classNames from "classnames";
import { ReactComponent as CamIcon } from "../../assets/camera.svg";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const CamNotSpecified: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  return (
    <div className={className} {...props}>
      <CamIcon className={styles["icon"]} />
      <span>Select a camera please</span>
    </div>
  );
};

export default CamNotSpecified;
