import { Backdrop } from "@mui/material";
import EditCameraForm from "./EditCameraForm";
import ClickOutsideComponent from "../ClickOutsideComponent";
import useEditLine from "./EditSignalLine/useEditLine";
import { ReactComponent as CheckIcon } from "./check.svg";
import { useState } from "react";
import EditScreen from "./EditScreen";
import { EditScreenProvider } from "./EditScreen/EditScreenProvider";
import { FC, HTMLProps } from "react";
import styles from "./EditCamera.module.scss";
import classNames from "classnames";

export type Props = {
  close: () => void;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const EditCamera: FC<Props> = (props) => {
  const { close, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const { handleLineSafe } = useEditLine();
  const [displaySavedModal, setDisplaySavedModal] = useState(false);

  const handleSafe = async () => {
    try {
      await handleLineSafe();
      setDisplaySavedModal(true);
      setTimeout(() => {
        setDisplaySavedModal(false);
      }, 2300);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Backdrop open={true} sx={{ zIndex: 20000 }}>
      <ClickOutsideComponent foo={close}>
        <div className={className} {...props}>
          {displaySavedModal && (
            <div className={styles["saved"]}>
              <CheckIcon fontSize="inherit" />
              <p>Saved</p>
            </div>
          )}
          <EditScreenProvider>
            <EditScreen />
            <EditCameraForm handleSafe={handleSafe} />
          </EditScreenProvider>
        </div>
      </ClickOutsideComponent>
    </Backdrop>
  );
};

export default EditCamera;
