import React, { useState } from "react";
import { transferToSVGCoords } from "./functions";

export type Point = [number, number];

type PointProps = {
  p: Point;
  svgRef: React.RefObject<SVGSVGElement>;
  handlePointChange: (x: number, y: number) => void;
  color?: string;
};

const EditPoint = ({ p, handlePointChange, svgRef, color }: PointProps) => {
  const [isDragging, setIsDragging] = useState(false);

  function drag(e: MouseEvent) {
    const { x, y } = transferToSVGCoords(e.clientX, e.clientY, svgRef);
    handlePointChange(x, y);
    setIsDragging(true);
  }

  return (
    <circle
      cx={p[0]}
      cy={p[1]}
      r={10}
      fill={color || "red"}
      stroke={"black"}
      strokeOpacity={0}
      strokeWidth={50} // большая ширина, чтобы можно было щелкать рядом с точкой
      style={{
        cursor: isDragging ? "grabbing" : "grab",
      }}
      onMouseDown={() => {
        svgRef.current?.addEventListener("mousemove", drag);
        svgRef.current?.addEventListener("mouseup", () => {
          svgRef.current?.removeEventListener("mousemove", drag);
          setIsDragging(false);
        });
        svgRef.current?.addEventListener("mouseleave", () => {
          svgRef.current?.removeEventListener("mousemove", drag);
          setIsDragging(false);
        });
      }}
    />
  );
};

export default EditPoint;
