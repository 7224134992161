export const transferToSVGCoords = (
  x: number,
  y: number,
  svgRef: React.RefObject<SVGSVGElement>
) => {
  let p = svgRef.current!.createSVGPoint();
  p.x = x;
  p.y = y;
  let ctm = (svgRef.current as any).getScreenCTM().inverse();
  p = p.matrixTransform(ctm);
  return p;
};

export const getDefaultRoiCoords = (
  imageWidth: number,
  imageHeight: number
) => {
  const HORIZONTAL_MARGIN = 0.1;
  const VERTICAL_MARGIN = 0.2;
  const horizontalMargin = imageWidth * HORIZONTAL_MARGIN;
  const verticalMargin = imageHeight * VERTICAL_MARGIN;
  return [
    horizontalMargin,
    verticalMargin,
    imageWidth - horizontalMargin,
    imageHeight - verticalMargin,
  ];
};
