import { useEffect, useState, useCallback } from "react";
import { useSelector } from "../../../store/reduxHooks";
import { tryToGetImage } from "../../../utils/functions";
import { useEditScreenContext } from "../EditScreen/EditScreenProvider";

const { API_URL } = window;

const useEditScreen = () => {
  const {
    imageParams,
    setImageParams,
    wrapperParams,
    setWrapperParams,
    editImageRef,
  } = useEditScreenContext();
  const [aspectRatio, setAspectRatio] = useState(0); // cоотношение сторон картинки
  const [imageHref, setImageHref] = useState("");

  const { editCameraId, videoUrl } = useSelector((state) => state.camera);

  // получение параметров контейнера, в котором находится svg
  const wrapperRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        // ширина контейнера выставляется автоматически
        // высота вычисляется из соотношения сторон картинки
        setWrapperParams({
          width: node.clientWidth,
          height: node.clientWidth * aspectRatio,
        });
      }
    },
    [aspectRatio]
  );

  // получение размеров картинки
  const imageRef = useCallback(
    (node: SVGImageElement) => {
      if (node !== null && editCameraId) {
        tryToGetImage(
          `${API_URL}/cameras/${editCameraId}/frame`,
          setImageHref,
          setImageParams
        );
      }
    },
    [editCameraId]
  );

  useEffect(() => {
    if (!editCameraId) return;
    // подгружать новый фрейм каждые 0.2сек
    const intervalID = setInterval(() => {
      tryToGetImage(
        `${API_URL}/cameras/${editCameraId}/frame?tag=${new Date().getTime()}`,
        setImageHref,
        setImageParams
      );
    }, 200);
    return () => {
      clearInterval(intervalID);
    };
  }, [videoUrl]);

  useEffect(() => {
    setAspectRatio(imageParams.height / imageParams.width);
  }, [imageParams]);

  return {
    wrapperRef,
    wrapperParams,
    imageParams,
    imageRef,
    imageHref,
    editImageRef,
  };
};

export default useEditScreen;
