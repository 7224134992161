import { useEffect, useRef, useState } from "react";
import { useEditScreenContext } from "../EditScreen/EditScreenProvider";
import { bbox } from "../../../store/reducers/cameraReducer";
import { useDispatch, useSelector } from "../../../store/reduxHooks";
import { setRoiCoords } from "../../../store/reducers/editReducer";

const isROICorrect = (ROI: bbox) => {
  const [x1, y1, x2, y2] = ROI;
  return x2 - x1 > 10 && y2 - y1 > 10;
};

const useEditRoi = () => {
  // redux
  const { cameraList, editCameraId } = useSelector((state) => state.camera);
  const { roiCoords } = useSelector((state) => state.edit);
  const dispatch = useDispatch();

  // state
  const [ROI, setROI] = useState<bbox | null>(null);

  // custom hooks
  const { imageParams } = useEditScreenContext();

  // refs
  const svgRef = useRef<SVGSVGElement>(null);

  // functions
  const handlePointChange = (
    new_x: number,
    new_y: number,
    point_index: number
  ) => {
    const p_x = point_index * 2;
    const p_y = point_index * 2 + 1;
    setROI((prev) => {
      if (prev === null) return prev;
      const copy: bbox = [...prev];
      copy[p_x] = new_x;
      copy[p_y] = new_y;
      if (!isROICorrect(copy)) {
        return prev;
      }
      return copy;
    });
  };
  const updateROI = () => {
    // updates ROI state in redux state
    setROI((prev) => {
      dispatch(setRoiCoords(prev));
      return prev;
    });
  };

  const deleteROI = () => {
    setROI(null);
    dispatch(setRoiCoords(null));
  };

  useEffect(() => {
    setROI(roiCoords);
  }, [roiCoords]);

  useEffect(() => {
    const camera = cameraList.filter((cam) => cam.id === editCameraId)[0];
    setROI(camera.head_detection_parameters.roi);
    dispatch(setRoiCoords(camera.head_detection_parameters.roi));
  }, []);

  return {
    imageParams,
    ROI,
    svgRef,
    handlePointChange,
    updateROI,
    deleteROI,
  };
};

export default useEditRoi;
