import { FC, HTMLProps, useCallback, useState } from "react";
import styles from "./Crop.module.scss";
import classNames from "classnames";
import { EventType } from "../../store/reducers/eventsReducer";
import { formatDate, getImageSize } from "../../utils/functions";

const { API_URL } = window;

export type Props = {
  event: EventType;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Crop: FC<Props> = (props) => {
  const { event, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const { head_bbox_on_crop, direction } = event;

  const infoClass = classNames([styles["info"]], {
    [styles["dark"]]: direction == "left",
  });

  const [x1, x2, width, height] = head_bbox_on_crop;
  const [imageParams, setImageParams] = useState({ width: 0, height: 0 });
  const imageRef = useCallback(
    (node: SVGImageElement) => {
      if (node !== null) {
        getImageSize(setImageParams, `${API_URL}/events/${event.id}/crop`);
      }
    },
    [event]
  );

  return (
    <div className={className} {...props}>
      <div className={styles["img-container"]}>
        <svg viewBox={`0 0 ${imageParams.width} ${imageParams.height}`}>
          <image ref={imageRef} href={`${API_URL}/events/${event.id}/crop`} />
          <rect
            x={x1}
            y={x2}
            width={width}
            height={height}
            stroke={"red"}
            strokeWidth={3}
            fill={"white"}
            fillOpacity={0}
          />
        </svg>
      </div>
      <div className={infoClass}>
        <div>
          <span className={styles["info-title"]}>Date</span>
          <span className={styles["info-value"]}>
            {formatDate(event.timestamp).date}
          </span>
        </div>
        <div>
          <span className={styles["info-title"]}>Time</span>
          <span className={styles["info-value"]}>
            {formatDate(event.timestamp).time}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Crop;
