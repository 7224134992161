import { createSlice } from "@reduxjs/toolkit";
import { bbox, CameraReadObject } from "./cameraReducer";

export type EventType = {
  body_bbox_on_crop: bbox;
  body_bbox_on_frame: bbox;
  direction: "left" | "right";
  head_bbox_on_crop: bbox;
  head_bbox_on_frame: bbox;
  id: string;
  signal_line: {
    camera: CameraReadObject;
    coordinates: bbox;
    directions: {
      left: {
        name: string;
      };
      right: {
        name: string;
      };
    };
    event_generation_parameters: {
      distance_threshold_px: number;
    };
    id: string;
    name: string;
  };
  timestamp: string;
  track_id: string;
};

type StateType = {
  eventsList: EventType[];
};

const initialState: StateType = {
  eventsList: [],
};

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    addEvent: (state, action) => {
      const arrCopy = JSON.parse(JSON.stringify(state.eventsList));
      if (arrCopy.length > 50) {
        arrCopy.pop();
      }
      state.eventsList = [action.payload, ...arrCopy];
    },
    setEvents: (state, action) => {
      state.eventsList = action.payload;
    },
  },
});

export const { setEvents, addEvent } = eventSlice.actions;

export default eventSlice.reducer;
