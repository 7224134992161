import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    customPallete: {
      directions: {
        left: string;
        right: string;
      };
      eventLine: string;
    };
  }
  interface ThemeOptions {
    customPallete?: {
      directions?: {
        left: string;
        right: string;
      };
      eventLine?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#036ab5",
      dark: "#263b47",
      light: "#ffffff",
    },
    secondary: {
      main: "#57555b",
      dark: "#98979e",
      light: "#d8e3ec",
    },
  },
  customPallete: {
    directions: {
      left: "#036ab5",
      right: "#263b47",
    },
    eventLine: "#24fc03",
  },
});

export default theme;
