import { createSlice } from "@reduxjs/toolkit";

export type bbox = [number, number, number, number];

export type CameraReadObject = {
  id: string;
  name: string;
  video_url: string;
  use_video_timestamps: boolean;
  event_callback: {
    url: string;
    send_frame: boolean;
    send_crop: boolean;
  };
  crop_expand: {
    horizontal: number;
    top: number;
    bottom: number;
  };
  head_detection_parameters: {
    min_head_height_px: number;
    max_head_height_px: number | null;
    roi: bbox | null;
    confidence_threshold: number;
  };
  tracking_parameters?: {
    detection_interval_secs: number;
    visual_tracking_estimate_delta_secs: number;
    max_not_tracked_secs: number;
    bbox_smoothing_factor: number;
    matching_head_bbox_scale_factor: number;
    matching_iou_threshold: number;
  };
  event_generation_parameters: {
    track_extrapolation_secs: number;
    min_interval_between_events_secs: number;
  };
  signal_lines: {
    [key: string]: SignalLine;
  };
};

export type CameraWriteObject = {
  name: string;
  video_url: string;
  use_video_timestamps?: boolean;
  event_callback?: {
    url: string;
    send_crop?: boolean;
    send_frame?: boolean;
  } | null;
  crop_expand?: {
    horizontal?: number;
    top?: number;
    bottom?: number;
  };
  head_detection_parameters: {
    min_head_height_px: number | null;
    max_head_height_px: number | null;
    roi: bbox | null;
    confidence_threshold: number;
  };
  tracking_parameters?: {
    detection_interval_secs?: number;
    visual_tracking_estimate_delta_secs?: number;
    max_not_tracked_secs?: number;
    bbox_smoothing_factor?: number;
    matching_head_bbox_scale_factor?: number;
    matching_iou_threshold?: number;
  };
  event_generation_parameters?: {
    track_extrapolation_secs?: number;
    min_interval_between_events_secs?: number;
  };
};

export type SignalLine = {
  coordinates: [number, number, number, number];
  directions: {
    left: {
      name: string;
    };
    right: {
      name: string;
    };
  };
  event_generation_parameters: {
    distance_threshold_px: number;
  };
  id: string;
  name: string;
};

type StateType = {
  cameraList: CameraReadObject[];
  selectedCamId: string;
  showCameraList: boolean;
  readonly: boolean;
  editCameraId: string;
  showAddCameraForm: boolean;
  videoUrl: string;
};

const initialState: StateType = {
  cameraList: [],
  selectedCamId: "",
  showCameraList: false,
  readonly: false,
  editCameraId: "",
  showAddCameraForm: false,
  videoUrl: "",
};

export const cameraSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setCameraList: (state, action) => {
      state.cameraList = action.payload;
    },
    setSelectedCamera: (state, action) => {
      state.selectedCamId = action.payload;
    },
    setShowCameraList: (state, action) => {
      state.showCameraList = action.payload;
    },
    setReadOnly: (state, action) => {
      state.readonly = action.payload;
    },
    setEditCameraId: (state, action) => {
      state.editCameraId = action.payload;
    },
    setShowAddCameraForm: (state, action) => {
      state.showAddCameraForm = action.payload;
    },
    setVideoUrl: (state, action) => {
      state.videoUrl = action.payload;
    },
  },
});

export const {
  setCameraList,
  setSelectedCamera,
  setShowCameraList,
  setReadOnly,
  setEditCameraId,
  setShowAddCameraForm,
  setVideoUrl,
} = cameraSlice.actions;

export default cameraSlice.reducer;
