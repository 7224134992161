import {
  FC,
  HTMLProps,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./CameraList.module.scss";
import classNames from "classnames";
import useCamera from "../../utils/hooks/useCamera";
import {
  CameraReadObject,
  setEditCameraId,
  setShowAddCameraForm,
} from "../../store/reducers/cameraReducer";
import ConfirmDelete from "../ConfirmDelete";
import { EditProvider } from "../EditCamera/EditProvider";
import EditCamera from "../EditCamera";
import CameraPreview from "../CameraPreview";
import gsap from "gsap";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as AddIcon } from "./add.svg";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const CameraList: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const tl = useRef<gsap.core.Timeline>();
  const [urlParams] = useSearchParams();
  const camId = urlParams.get("camId");
  const {
    cameraList,
    handleSelect,
    selectedCamId,
    deleteCamera,
    setShowDeleteConfirm,
    readonly,
    showEditCam,
    dispatch,
    showDeleteConfirm,
  } = useCamera();
  const [listOpen, setListOpen] = useState(false);
  const [deleteCam, setDeleteCam] = useState<CameraReadObject>();

  const list = useRef(null);
  const arrow = useRef(null);

  const handleHover = (e: React.MouseEvent) => {
    e.preventDefault();
    if (tl.current?.isActive()) return;
    if (!listOpen) {
      setListOpen(true);
    }
  };

  const handleLeave = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (tl.current?.isActive()) return;
    setListOpen(false);
  };

  const closeConfirmation = () => {
    setShowDeleteConfirm(false);
  };

  const handleCamDelete = useCallback(() => {
    deleteCam && deleteCamera(deleteCam.id);
  }, [deleteCam, deleteCamera]);

  const proposeDelete = (e: React.MouseEvent, camId: string) => {
    e.stopPropagation();
    const camera = cameraList.filter((c) => c.id === camId)[0];
    setDeleteCam(camera);
    setShowDeleteConfirm(true);
  };

  const showAddCamForm = () => {
    dispatch(setShowAddCameraForm(true));
  };

  useLayoutEffect(() => {
    gsap.set(list.current, { x: "-100%", y: "-50%" });
  }, []);

  useLayoutEffect(() => {
    setListOpen(!camId);
  }, [camId]);

  useLayoutEffect(() => {
    if (listOpen) {
      gsap.to(list.current, { x: "0%" });
    } else {
      gsap.to(list.current, { x: "-100%" });
    }
  }, [listOpen]);

  return (
    <>
      {showDeleteConfirm && (
        <ConfirmDelete
          handleClose={closeConfirmation}
          deleteFunction={handleCamDelete}
          name={deleteCam?.name}
        />
      )}
      {showEditCam && (
        <EditProvider>
          <EditCamera close={() => dispatch(setEditCameraId(""))} />
        </EditProvider>
      )}
      <div
        className={className}
        {...props}
        ref={list}
        onMouseLeave={(e) => handleLeave(e)}
      >
        <div className={styles["list"]}>
          {cameraList.map((camera, i) => (
            <CameraPreview
              key={camera.id}
              camera={camera}
              selected={selectedCamId === camera.id}
              proposeDelete={(e) => {
                proposeDelete(e, camera.id);
              }}
              onClick={() => handleSelect(camera.id)}
            />
          ))}
          {!readonly && (
            <div className={styles["add-camera"]} onClick={showAddCamForm}>
              <AddIcon />
              <span>Add new camera</span>
            </div>
          )}
        </div>
        <div className={styles["puller"]} onMouseEnter={handleHover}>
          <CameraIcon ref={arrow} />
        </div>
      </div>
    </>
  );
};

export default CameraList;
