import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import useNotify from "../utils/hooks/useNotify";

type TransitionProps = Omit<SlideProps, "direction">;

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

const Notification = () => {
  const { message, type, notify } = useNotify();
  const handleClose = () => {
    notify("", "error");
  };
  return (
    <Snackbar
      open={!!message}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
