import { FC, HTMLProps } from "react";
import styles from "./Counter.module.scss";
import classNames from "classnames";
import useCounter from "../../utils/hooks/useCounter";
import CountBlock from "./CountBlock";
import { ReactComponent as GroupIconFilled } from "./group.svg";
import { ReactComponent as GroupIconOutlined } from "./group_outlined.svg";
import { ReactComponent as ResetIcon } from "./reset.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import { useDispatch, useSelector } from "../../store/reduxHooks";
import { setEditCameraId } from "../../store/reducers/cameraReducer";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const Counter: FC<Props> = (props) => {
  const { left, right, directionsNames, resetCounter, readonly } = useCounter();
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const dispatch = useDispatch();
  const { selectedCamId } = useSelector((state) => state.camera);

  const handleConfigure = () => {
    dispatch(setEditCameraId(selectedCamId));
  };

  return (
    <div className={className} {...props}>
      <CountBlock
        name={directionsNames.leftName}
        count={left}
        Icon={GroupIconFilled}
        line
      />
      <CountBlock
        name={directionsNames.rightName}
        count={right}
        Icon={GroupIconOutlined}
        outlined
      />
      <div className={styles["buttons-container"]}>
        {!readonly && selectedCamId && (
          <div className={styles["button"]} onClick={handleConfigure}>
            <span>Setup</span>
            <SettingsIcon />
          </div>
        )}
        {selectedCamId && (
          <div className={styles["button"]} onClick={resetCounter}>
            <span>Reset</span>
            <ResetIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default Counter;
