import { FC, HTMLProps } from "react";
import classNames from "classnames";

import styles from "./Language.module.scss";

export type Props = {
  title: string;
  selected?: boolean;
  light?: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLSpanElement>;

const Language: FC<Props> = (props) => {
  const { selected, light, title, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {
      [styles["selected"]]: selected,
      [styles["light"]]: light,
    }
  );
  return (
    <span className={className} {...props}>
      {title}
    </span>
  );
};

export default Language;
