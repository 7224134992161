import { FC, useState, HTMLProps } from "react";
import { LoadingButton } from "@mui/lab";
import { Alert, Backdrop, Button } from "@mui/material";
import ClickOutsideComponent from "../../utils/ClickOutsideComponent";
import styles from "./ConfirmDelete.module.scss";
import classNames from "classnames";

export type Props = {
  deleteFunction: () => void;
  handleClose: () => void;
  name?: string;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const ConfirmDelete: FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { deleteFunction, handleClose, name, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const handleDelete = async () => {
    try {
      setLoading(true);
      setErrorMessage("");
      await deleteFunction();
      handleClose();
    } catch (error) {
      setErrorMessage("Что-то пошло не так!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Backdrop open>
      <ClickOutsideComponent
        foo={() => {
          !loading && handleClose();
        }}
      >
        <div className={className} {...props}>
          <h2 className={styles["form-name"]}>{`Удалить камеру ${name}?`}</h2>
          {errorMessage && (
            <Alert className={styles["error"]} severity="error">
              {errorMessage}
            </Alert>
          )}
          <div className={styles["buttons-container"]}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={loading}
              onClick={handleDelete}
            >
              Удалить
            </LoadingButton>
            <Button onClick={handleClose}>Отменить</Button>
          </div>
        </div>
      </ClickOutsideComponent>
    </Backdrop>
  );
};

export default ConfirmDelete;
