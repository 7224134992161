import Counter from "../components/Counter";
import VideoContainer from "../components/VideoContainer";
import Crops from "../components/CropsList";
import styles from "./Home.module.scss";

const HomePage = () => {
  return (
    <div className={styles["container"]}>
      <VideoContainer />
      <Counter addClasses={[styles["counter"]]} />
      <Crops addClasses={[styles["crops"]]} />
    </div>
  );
};

export default HomePage;
