import { FC, HTMLProps, useEffect, useState } from "react";
import styles from "./CameraPreview.module.scss";
import classNames from "classnames";
import {
  CameraReadObject,
  setEditCameraId,
} from "../../store/reducers/cameraReducer";
import { useDispatch, useSelector } from "../../store/reduxHooks";
import { tryToGetImage } from "../../utils/functions";
import NoImage from "../../assets/no_image.png";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";

const { API_URL } = window;

export type Props = {
  camera: CameraReadObject;
  onClick: () => void;
  proposeDelete: (e: React.MouseEvent, id: string) => void;
  selected: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const CameraPreview: FC<Props> = (props) => {
  const { selected, camera, proposeDelete } = props;
  const { readonly } = useSelector((state) => state.camera);
  const [imageHref, setImageHref] = useState("");
  const dispatch = useDispatch();

  const handleSettings = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    dispatch(setEditCameraId(id));
  };

  const { name, id } = camera;

  useEffect(() => {
    tryToGetImage(`${API_URL}/cameras/${id}/frame`, setImageHref);
  }, []);

  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    { [styles["selected"]]: selected }
  );

  return (
    <div className={className} {...props}>
      <img
        className={styles["img"]}
        src={!imageHref || imageHref === "NoImage" ? NoImage : imageHref}
        alt={name}
      />
      <div className={styles["info"]}>
        <span>{name}</span>
        {!readonly && (
          <div className={styles["icons"]}>
            <SettingsIcon
              className={styles["icon"]}
              onClick={(e) => handleSettings(e, id)}
            />

            <DeleteIcon
              className={styles["icon"]}
              onClick={(e) => proposeDelete(e, id)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CameraPreview;
