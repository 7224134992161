import { useDispatch, useSelector } from "../../store/reduxHooks";
import { setNotification } from "../../store/reducers/notificationReducer";

const useNotify = () => {
  const { message, type } = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  const notify = (message: string, type: "info" | "error") => {
    dispatch(setNotification({ message, type }));
  };

  return {
    message,
    type,
    notify,
  };
};

export default useNotify;
