import { createSlice } from "@reduxjs/toolkit";

type InitStateType = {
  message: string;
  type: "info" | "warning" | "error";
};

const initialState: InitStateType = {
  message: "",
  type: "info",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      const { message, type } = action.payload;
      state.message = message;
      state.type = type;
    },
  },
});

export const { setNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
