import { getPerpendicularCoords } from "./Arrow";

export const transferToSVGCoords = (
  x: number,
  y: number,
  svgRef: React.RefObject<SVGSVGElement>
) => {
  let p = svgRef.current!.createSVGPoint();
  p.x = x;
  p.y = y;
  let ctm = (svgRef.current as any).getScreenCTM().inverse();
  p = p.matrixTransform(ctm);
  return p;
};

export function getCoeffs(
  p1: { x: number; y: number },
  p2: { x: number; y: number }
) {
  // line equation
  // (y-y0)=m(x-x0) ---> ax - by - c = 0
  const [a, b] = [p1.y - p2.y, p2.x - p1.x];
  const c = -a * p1.x - b * p1.y;
  return { a, b, c };
}

export const getParallelLineCoords = (
  x1: number,
  x2: number,
  y1: number,
  y2: number,
  direction: number,
  distance: number
) => {
  const p1 = getPerpendicularCoords(x1, x2, y1, y2, direction, distance, 1);
  const p2 = getPerpendicularCoords(x2, x1, y2, y1, direction, distance, 1);
  const centerX = (p1.px + p2.px) / 2;
  const centerY = (p1.py + p2.py) / 2;
  return { p1, p2, centerX, centerY };
};
