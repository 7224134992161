import { useState } from "react";
import { useDispatch, useSelector } from "../../store/reduxHooks";

const useAddCamera = () => {
  const { cameraList, showAddCameraForm, editCameraId } = useSelector(
    (state) => state.camera
  );
  const dispatch = useDispatch();
  const [loads, setLoads] = useState(false);

  return {
    loads,
    showAddCameraForm,
    dispatch,
    editCameraId,
    setLoads,
    cameraList,
  };
};

export default useAddCamera;
