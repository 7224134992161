import { FC, HTMLProps } from "react";
import styles from "./CountBlock.module.scss";
import classNames from "classnames";

export type Props = {
  name: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  count: number;
  line?: boolean;
  outlined?: boolean;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const CountBlock: FC<Props> = (props) => {
  const { outlined, name, Icon, count, line, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    { [styles["line"]]: line }
  );

  const countClass = classNames([styles["count"]], {
    [styles["outlined"]]: outlined,
  });

  return (
    <div className={className} {...props}>
      <span className={styles["name"]}>{name}</span>
      <span className={countClass}>{count}</span>
      <Icon className={styles["icon"]} />
    </div>
  );
};

export default CountBlock;
